import React from "react"
import styled from "@emotion/styled"
import systemCss from "@styled-system/css"
import { color, space, size, border } from "styled-system"

// Media queries utils
import { sm, md, lg, xl } from "../../utils/mediaQueries"

const BaseButton = styled.button`
  display: inline-block;
  cursor: pointer;
  outline: none;
  border-radius: 8rem;
  line-height: 1.25;
  font-weight: 700;
  border-width: 4px;
  border-style: solid;
  z-index: 2;
  padding: 2rem 4rem;

  ${props =>
    systemCss({
      fontSize:
        props.size == "sm"
          ? "btn.sm"
          : props.size == "md"
          ? "btn.md"
          : "btn.lg",
      height:
        props.size == "sm"
          ? "btn.sm"
          : props.size == "md"
          ? "btn.md"
          : "btn.lg",
      padding:
        props.size == "sm" ? "btn.sm" : props.size == "md" ? "btn.md" : "btn.lg"
    })}

  transition: transform 0.4s, box-shadow 0.4s;
  &:hover {
    transform: translateY(-4px);
  }
`

const PrimaryButton = styled(BaseButton)`
  ${systemCss({
    color: "white",
    bg: "purple",
    borderColor: "purple"
  })}
  transition: all .2s;

  ${color}
  ${space}
  ${size}
  ${border}
`

export const SecondaryButton = styled(BaseButton)`
  ${systemCss({
    color: "purple",
    bg: "white",
    borderColor: "purple"
  })}

  &:hover{
    ${systemCss({
      color: "white",
      bg: "purple",
      borderColor: "purple"
    })}
  }

  ${color}
  ${space}
  ${size}
  ${border}
`

export const RoundButton = styled.button`
  height: 8rem;
  width: 8rem;
  position: relative;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  border: none;
  cursor: pointer;
  outline: none;

  ${systemCss({
    bg: "dark"
  })}

  & > img {
    position: absolute;
    height: 4.8rem;
    top: 50%;
    left: 50%;
    margin-top: -2.4rem;
    margin-left: -2.4rem;
  }
  ${sm} {
    height: 5.6rem;
    width: 5.6rem;
    & > img {
      height: 3.2rem;
      margin-top: -1.6rem;
      margin-left: -1.6rem;
    }
  }

  ${color}
`

export default PrimaryButton
